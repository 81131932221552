var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    attrs: {
      id: "content"
    }
  }, [_vm._m(0), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("合作单位")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Partner")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("合作單位")]) : _vm._e()]), _c("img", {
    attrs: {
      src: require("../assets/innovation_leads.jpg")
    }
  })])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("学术认同")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Academic Ientity")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("學術認同")]) : _vm._e()]), _c("img", {
    attrs: {
      src: require("../assets/innovation_leads2.jpg")
    }
  })])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "companyBlock",
    staticStyle: {
      background: "#e6f8f4",
      "margin-bottom": "4em"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("癌症大数据")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Cancer Data")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("癌症大數據")]) : _vm._e()]), _c("img", {
    attrs: {
      src: require("../assets/innovation_leads3.jpg")
    }
  })])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("合作单位")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Partner")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("合作單位")]) : _vm._e()]), _c("img", {
    attrs: {
      src: require("../assets/innovation_mobile_leads.jpg")
    }
  })])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("学术认同")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Academic Ientity")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("學術認同")]) : _vm._e()]), _c("img", {
    attrs: {
      src: require("../assets/innovation_mobile_leads2.jpg")
    }
  })])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    staticClass: "companyBlock"
  }, [_c("div", {
    staticClass: "companyBlock-about active"
  }, [_c("div", {
    staticClass: "companyBlock-hd"
  }, [_vm.language == 1 ? _c("h2", [_vm._v("癌症大数据")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("Cancer Data")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("癌症大數據")]) : _vm._e()]), _c("img", {
    attrs: {
      src: require("../assets/innovation_mobile_leads3.jpg")
    }
  })])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "aboutBannerBox"
  }, [_c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/kyhz.jpg")
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };