import { mapState } from 'vuex';
export default {
  name: '',
  components: {},
  props: {},

  data() {
    return {};
  },

  computed: { ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  watch: {},

  created() {},

  mounted() {},

  methods: {}
};